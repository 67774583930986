<template>
  <div class="app-box">
    <div class="blockBox">
      <!-- end -->
      <div class="centAuot">
        <p class="centText">订单信息</p>
        <div class="mboeBox">
          <div class="leftbox">创建时间：</div>
          <div class="rightbox">2021-06-23</div>
        </div>

        <div class="mboeBox">
          <div class="leftbox">订单编号：</div>
          <div class="rightbox">
            2104836373205062158<span class="rightCope">复制</span>
          </div>
        </div>

        <div class="mboeBox">
          <div class="leftbox">运单号码：</div>
          <div class="rightbox">
            1236523696988564123<span class="rightCope">复制</span>
          </div>
        </div>
      </div>
      <!-- end -->
      <div class="borderBottom">
        <van-button type="default" size="mini" @click="show = true"
          >联系客服</van-button
        >
        <van-button
          class="boeright"
          type="default"
          size="mini"
          @click="show1 = true"
          >联系物流</van-button
        >
      </div>
    </div>

    <div class="blockBox">
      <!-- end -->
      <div class="centAuot">
        <p class="centText">物流记录</p>
        <div>
          <van-steps
            direction="vertical"
            :active="0"
            active-color="#0E8AFFFF"
            inactive-color="#B8B9C2FF"
            active-icon="checked"
          >
            <van-step>
              <div class="fontSIze">
                <h3>已运输</h3>
                <p>2016-07-12 12:40</p>
              </div>
            </van-step>
            <van-step>
              <div class="fontSIze">
                <h3>已揽件</h3>
                <p>2016-07-12 12:40</p>
              </div>
            </van-step>
            <van-step>
              <div class="fontSIze">
                <h3>已发货</h3>
                <p>2016-07-12 12:40</p>
              </div>
            </van-step>
          </van-steps>
        </div>
      </div>
      <!-- end -->
    </div>
    <!-- end电话 -->
    <van-action-sheet
      v-model="show"
      :actions="actions"
      @select="onSelect"
      cancel-text="取消"
    />
    <van-action-sheet
      v-model="show1"
      :actions="actions1"
      @select="onSelect1"
      cancel-text="取消"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      show1: false,
      actions: [{ name: "400-861-8683" }],
      actions1: [{ name: "400-861-8683" }],
    };
  },
  methods: {
    onSelect(item) {
      this.show = false;
      window.location.href = `tel:${item.name}`;
    },
    onSelect1(item) {
      this.show1 = false;
      window.location.href = `tel:${item.name}`;
    },
  },
};
</script>

<style scoped>
.app-box {
  height: 100vh;
  background-color: #efeff4;
  box-sizing: border-box;
}

.blockBox {
  padding: 0.3rem 0.3rem;
  box-sizing: border-box;
  border-bottom: 7px solid #edf2f5ff;
  background: #fff;
}
.titleBox {
  position: relative;
  padding-left: 0.2rem;
  font-size: 0.43rem;
  font-weight: bold;
}
.titleBox::before {
  content: "";
  display: inline-block;
  width: 4px;
  border-radius: 10px;
  height: 15px;
  background: #0e8affff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.centAuot {
  background: #fff;
  overflow: hidden;
  margin-bottom: 5px;
}
.centAuot .left {
  float: left;
}
.centAuot .left img {
  width: 1.3rem;
}
.centAuot .right {
  margin-left: 1.5rem;
  font-size: 0.48rem;
}
.centAuot .centText {
  font-size: 0.42rem;
  font-weight: bold;
  position: relative;
  padding-left: 10px;
}
.centAuot .centText::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 15px;
  background: #0e8affff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.rightxope {
  float: right;
  background: rgba(14, 138, 255, 0.1);
  color: #0e8affff;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 0.43rem;
}

.coloeTxt {
  color: #0e8affff;
  font-size: 0.43rem;
  font-weight: bold;
  text-align: center;
  padding: 0.4rem 0;
  padding-bottom: 0.2rem;
}
.coloeTxt .icon-shijian {
  margin-right: 0.1rem;
  font-size: 0.5rem;
}
.timeBox {
  background: #0e8affff;
  display: inline-block;
  padding: 0.2rem 0.2rem;
  border-radius: 10px;
}
.timeUl {
  text-align: center;
  margin-top: 0.1rem;
}
.timeUl ::v-deep.van-count-down {
  display: inline-block;
}
.iconbsn {
  display: inline-block;
  background: RGBA(214, 231, 246, 1);
  color: #0e8aff;
  padding: 0.1rem 0.2rem;
  border-radius: 10px;
  font-size: 0.43rem;
}
.coloerihe {
  color: #0e8aff;
}
.UlBox {
  width: 100%;
  background: #fff;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  border-bottom: 5px solid #edf2f5ff;
}
.titleH3 {
  font-size: 0.43rem;
  position: relative;
  padding-left: 10px;
  font-weight: bold;
}
.titleH3::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background: #0e8aff;
  border-radius: 10px;
}
.li .pTitle {
  color: #333b5c;
  font-size: 0.4rem;
  line-height: 1rem;
}
.li .HTitle {
  font-size: 0.43rem;
  color: #000;
  padding-right: 2rem;
}
.mapeBox {
  border-bottom: 1px solid #fff3f3f5;
  padding: 0.3rem 0;
  position: relative;
}
.mapeBox .copy {
  position: absolute;
  right: 0;
  bottom: 0.34rem;
  font-size: 0.43rem;
  color: #0e8aff;
}
.li .mapeBox:nth-last-child(1) {
  border: none;
}
.TopBox {
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;
  padding-top: 0;
  width: 100%;
  margin-top: 0.3rem;
}
.TopBox .left {
  float: left;
}
.TopBox .right {
  margin-left: 2.3rem;
  font-size: 0.4rem;
}
.TopBox .right p {
  line-height: 0.7rem;
}
.TopBox .left img {
  width: 2rem;
}
.right .spanColor {
  color: #333b5cff;
}
.TopBox .service {
  font-size: 0.43rem;
  color: #333b5cff;
  line-height: 1rem;
  border-bottom: 1px solid #f3f3f5ff;
}
.fr {
  float: right;
  color: #0e8aff;
}
.serviceText {
  font-size: 0.43rem;
  color: #333b5cff;
  margin-top: 0.3rem;
}
.titleBoxtxt {
  font-size: 0.4rem;
  margin-top: 0.2rem;
}
.positFlex {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  line-height: 1.5rem;
  box-sizing: border-box;
}
.positFlex .liFlex {
  flex: 4;
  font-size: 0.45rem;
}
.positFlex .liFlex p {
  padding: 0 0.3rem;
}
.positFlex .liFlex1 {
  flex: 2;
  text-align: center;
  font-size: 0.45rem;
  background: #0e8affff;
  color: #fff;
}
.colornkae {
  color: #fb4a51ff;
}
.titleH31 {
  background: #fff;
  padding: 0.3rem;
}
.titleirmb {
  text-align: right;
  background: rgba(242, 249, 255, 1);
  border-radius: 10px;
  line-height: 1.5rem;
  font-size: 0.43rem;
  padding-right: 0.5rem;
}
.rmbColor {
  color: #fb4a51ff;
}
.imgWIdhr {
  width: 80px;
  height: 80px;
  margin-right: 0.2rem;
}
.imgFor {
  position: relative;
  float: left;
  overflow: hidden;
  margin-bottom: 0.2rem;
}
.imgFor .meoae {
  position: absolute;
  top: 0;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 5px;
}
.imgFor .meoae1 {
  font-size: 0.2rem;
  color: #fff;
}

.pokeoBox ::v-deep.van-button {
  border-radius: 10px;
}
.poperBox {
  padding: 0.5rem 0.5rem;
}
.poperBox img {
  width: 120px;
  height: 100px;
  margin: auto;
}
.sucerr {
  text-align: center;
  font-size: 0.4rem;
  color: #666c85ff;
  padding: 0.3rem 0;
}
::v-deep.van-button {
  border-radius: 10px;
}
.mboeBox {
  display: flex;
  font-size: 0.42rem;
  margin: 0.2rem 0;
}
.mboeBox .leftbox {
  flex: 1;
  color: #333b5cff;
}
.mboeBox .rightbox {
  flex: 3;
  color: #000;
}
.mboeBoxBoder {
  border-bottom: 1px solid #f3f3f5ff;
  padding: 0.3rem 0;
}
.rightCope {
  float: right;
  color: #0e8affff;
}
.ulBoxImg {
  overflow: hidden;
}
.ulBoxImg .liImg {
  float: left;
  margin-right: 0.1rem;
  margin-bottom: 0.15rem;
}
.ulBoxImg .liImg img {
  width: 3rem;
  height: 2rem;
  border-radius: 5px;
}
.ulBoxImg .liImg:nth-last-child(1) {
  margin-right: 0;
}
.sopeff {
  color: #666c85ff;
  font-size: 0.35rem;
}
.sopefftitl {
  margin: 0.1rem 0;
}
.sopefftitl1 {
  line-height: 1rem;
}
.borderBottom {
  text-align: center;
}
.borderBottom ::v-deep.van-button {
  padding: 0.4rem 0.5rem;
  border-radius: 20px;
  border: 1px solid #000;
  font-size: 0.35rem;
}
.boeright {
  margin-left: 30px;
}
.centAuot ::v-deep.van-step__circle {
  width: 10px;
  height: 10px;
}
.centAuot ::v-deep.van-step--vertical .van-step__circle-container {
  font-size: 15px;
}
.centAuot .fontSIze{
    font-size: .3rem;
}
.centAuot .fontSIze h3{
    font-size: .4rem;
}
</style>